<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">保管柜模组设备</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <img src="../../../../assets/mobile/img/product/equipment.png" alt="" class="pic">
                <div class="title">保管柜模组设备</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯智能保管柜模组设备系列包含管理网关模组、恒温除湿模组、配电模组、通风模组、锁控模组以及安全识别模组。系统具有丰富的控制功能，硬件系统与控制策略可灵活配置组合，系统可配置温湿度检测模组、通风模组、恒温除湿模组、冷凝除湿模组、凝水液位检测模组等，实现柜内环境调节、柜内有害气体排出等多种控制功能，实现不同应用的需求。
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>
    
<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>
  
<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
    background-size: 335%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 700px;
    height: 200px;
    font-size: 72px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    text-align: justify;
    background-color: #ffffff;
}

.container .containerBox .pic {
    margin-left: 320px;
    margin-bottom: 150px;
    width: 1000px;
}

.container .containerBox .title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 80px;
}

.container .containerBox .text {
    margin-bottom: 50px;
    font-size: 70px;
    color: #999;
}
</style>